import { newsletterSignupLocations } from 'constants/newsletterSubscriptionLocation';
import NewsletterSignup, {
  NewsLetterSignupPresentationProps,
} from 'components/newsletter/functional/NewsletterSignup';
import NewsletterSignupContent from 'components/newsletter/presentational/NewsletterSignupContent';

export const NewsLetterInFooter = () => {
  return (
    <div className="container my-4">
      <NewsletterSignup
        websiteLocation={newsletterSignupLocations.LOCATION_FOOTER}
        newsLetterPresentation={(props: NewsLetterSignupPresentationProps) => (
          <NewsletterSignupContent {...props} />
        )}
      />
    </div>
  );
};
