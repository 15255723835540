import { useTranslation } from 'react-i18next';
import BigFilledButton from 'components/BigFilledButton';
import useResponsive from 'hooks/useResponsive';

export function BoxViewOrderDetailsButton({ orderDetailsPath }: { orderDetailsPath: string }) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  return (
    <BigFilledButton
      text={t('app.ui.view_this_order')}
      href={orderDetailsPath}
      rightArrowIcon={false}
      color="purple"
      width={isMobile ? 'w-full' : 'w-1/3'}
    />
  );
}
