import Image from 'next/image';
import config from 'next/config';
import { useTranslation } from 'react-i18next';

import { useResponsive } from 'hooks/useResponsive';
import trustpilotImage from '../../../public/trustpilot.png';

const { publicRuntimeConfig } = config();

export const TrustpilotWidget = () => {
  const { isLg } = useResponsive();
  const { t } = useTranslation();

  return (
    <div
      id="tealium-trust-pilot"
      className="trustpilot-widget"
      data-locale="nl-NL"
      data-template-id="53aa8912dec7e10d38f59f36"
      data-businessunit-id={publicRuntimeConfig.trustpilotBusinessId}
      data-style-height="130px"
      data-style-width="100%"
      data-theme="light"
      data-stars="1,2,3,4,5"
    >
      <a
        target="_blank"
        rel="noreferrer"
        href={publicRuntimeConfig.trustpilotReviewUrl}
        className="flex justify-center mt-8"
      >
        <div>
          <Image
            src={trustpilotImage}
            alt={t('app.ui.trustpilot_logo.alt')}
            height="50"
            width="200"
            quality={isLg ? 100 : 75}
          />
          <p className="hover:underline mt-1">Lees hier 5750+ reviews</p>
        </div>
      </a>
    </div>
  );
};
