import { doFetch, resolveApiUrl } from 'lib/api';
import { AdyenSessionCheckout } from 'types/types';
import API_ENDPOINTS from 'constants/routes/api';

export default async function fetchPaymentSession(
  paymentSessionId: string,
  orderTokenValue: string
): Promise<AdyenSessionCheckout> {
  const endpoint = resolveApiUrl(
    API_ENDPOINTS.GetPaymentSession(paymentSessionId, orderTokenValue)
  );
  const res = await doFetch(endpoint, {
    headers: { accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'GET',
    redirect: 'follow',
    mode: 'cors',
  });

  if (!res.ok) {
    throw new Error('Fetch payment session failed');
  }

  return await res.json();
}
