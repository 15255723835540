import React, { useState } from 'react';
import LocationSearchLabel from 'components/location/search/LocationSearchLabel';
import { useTranslation } from 'react-i18next';
import ClickAwayBackdrop from 'components/common/ClickAwayBackdrop';
import { useAppDispatch, useAppSelector } from 'redux/appStore';
import {
  getSubscriptionLocationLabel,
  setNewsletterSubscriptionLocation,
} from 'redux/common/common.slice';
import DropdownPanel from 'components/common/DropdownPanel';
import LocationAutocompleteInput from 'components/location/filters/LocationAutocompleteInput';
import LocationCityList from 'components/location/search/LocationCityList';
import { GeoFilter } from 'types/geolocation';
import { LOCATION_SELECTOR_VARIANT } from 'constants/geolocation';
import { LocationSelectionEventTag } from 'modules/tracking/tags/LocationSelectionEventTag';

const NewsletterLocationSelector = () => {
  const { t } = useTranslation();
  const label = useAppSelector(getSubscriptionLocationLabel);
  const dispatch = useAppDispatch();

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const openDropdown = () => setIsDropdownOpen(true);
  const closeDropdown = () => setIsDropdownOpen(false);

  const onPlaceSelection = ({ query, label, city }: GeoFilter) => {
    dispatch(
      setNewsletterSubscriptionLocation({
        latitude: query.latitude,
        longitude: query.longitude,
        label: (label ?? city) as string,
      })
    );
    closeDropdown();
  };

  return (
    <div className="relative w-full md:w-auto md:mr-2">
      <LocationSearchLabel
        label={label}
        isDropdownOpen={isDropdownOpen}
        onClick={openDropdown}
        close={closeDropdown}
      />
      <ClickAwayBackdrop active={isDropdownOpen} onClick={closeDropdown} />
      <DropdownPanel show={isDropdownOpen}>
        <LocationAutocompleteInput
          variant={LOCATION_SELECTOR_VARIANT.NEWSLETTER}
          onChange={onPlaceSelection}
          close={closeDropdown}
        />
        <LocationCityList
          variant={LOCATION_SELECTOR_VARIANT.NEWSLETTER}
          onSelect={onPlaceSelection}
          trackLocationEventAction={LocationSelectionEventTag.EVENT_ACTION_NEWSLETTER_LOCATION}
        />
      </DropdownPanel>
    </div>
  );
};

export default NewsletterLocationSelector;
