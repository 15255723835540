import { useTranslation } from 'react-i18next';
import BigLink from 'components/BigLink';

export function BoxViewAllOrdersLink({ viewAllOrdersPath }: { viewAllOrdersPath: string }) {
  const { t } = useTranslation();
  return (
    <BigLink
      text={t('app.ui.view_all_orders')}
      href={viewAllOrdersPath}
      rightArrowIcon={true}
      color="purple"
    />
  );
}
