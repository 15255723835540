import { PaymentMethodIcon } from 'types/types';

const paymentMethodIcons: PaymentMethodIcon[] = [
  { path: '/paymentMethodIcons/apple-pay.svg', name: 'ApplePay' },
  { path: '/paymentMethodIcons/google-pay.svg', name: 'GooglePay' },
  { path: '/paymentMethodIcons/ideal.svg', name: 'iDeal' },
  { path: '/paymentMethodIcons/master-card.svg', name: 'MasterCard' },
  { path: '/paymentMethodIcons/visa.svg', name: 'Visa' },
];

export const paymentMethods = {
  icons: paymentMethodIcons,
};

export default paymentMethods;
