import { doFetch, getPublicRuntimeConfig, resolveApiUrl } from 'lib/api';
import API_ENDPOINTS from 'constants/routes/api';
import { DEFAULT_ADYEN_API_VERSION } from 'constants/common';

const { adyenApiVersion } = getPublicRuntimeConfig();
const version = adyenApiVersion || DEFAULT_ADYEN_API_VERSION;

export default async function fetchStartPayment(
  orderTokenValue: string,
  pspMethod: string,
  pspBank: string,
  paymentSessionId: string
): Promise<any> {
  const endpoint = resolveApiUrl(API_ENDPOINTS.GetStartPayment);
  const res = await doFetch(endpoint, {
    body: JSON.stringify({ orderTokenValue, pspMethod, pspBank, paymentSessionId, version }),
    headers: { accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'POST',
    redirect: 'follow',
    mode: 'cors',
  });

  if (!res.ok) {
    throw new Error('Start payment failed');
  }

  const json = await res.json();
  return json.pgwResponse;
}
