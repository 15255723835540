import Usp from './Usp';
import { useTranslation } from 'react-i18next';
import Box from './order/box/Box';

export function UniqueSellingPoints() {
  const { t } = useTranslation();
  return (
    <Box>
      <div className="mb-4 text-2xl font-extrabold">{t('app.ui.why_pay_more')}</div>

      <div className="text-base">
        <Usp text={t('app.ui.usp1')} />
        <Usp text={t('app.ui.usp2')} />
        <Usp text={t('app.ui.usp3')} />
      </div>
    </Box>
  );
}
