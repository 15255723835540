import { Booking } from 'types/types';

interface BookingCardDescriptionProps {
  booking: Booking;
}

export function BookingCardDescription({ booking }: BookingCardDescriptionProps) {
  return (
    <div className="font-normal text-xs pt-2 pb-6 sm:pb-0">
      {booking.descriptions.map((description, index) => (
        <p key={index} className="line-clamp-1">
          {description}
        </p>
      ))}
    </div>
  );
}
