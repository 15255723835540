import { CalendarIcon, FolderIcon, HomeIcon, LogoutIcon } from '@heroicons/react/outline';
import { classNames } from 'lib/classes';
import { useTranslation } from 'react-i18next';
import { signOut } from 'next-auth/react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import useStore from 'store';
import AccountRoutes from 'constants/routes/account';
import { FiShoppingBag } from 'react-icons/fi';

export function AccountMenu() {
  const { t } = useTranslation();
  const router = useRouter();
  const [_, setCustomer] = useStore('customer');
  const [__, setCart] = useStore('cart');

  const handleSignOut = () => {
    void setCustomer(null);
    void setCart(null);
    void signOut();
  };

  const navigation = [
    {
      name: t('app.ui.menu.account'),
      href: '/account/dashboard',
      icon: HomeIcon,
      current: router.pathname === '/account/dashboard',
    },
    {
      name: t('app.ui.menu.my_orders'),
      href: '/account/orders',
      icon: FiShoppingBag,
      current: router.pathname.startsWith('/account/orders'),
    },
    {
      name: t('app.ui.menu.returns'),
      href: '/account/retourneren',
      icon: FolderIcon,
      current: [
        '/account/geretourneerd',
        '/account/retour-aanmelden',
        '/account/retourneren',
      ].includes(router.pathname),
    },
    {
      name: t('app.ui.menu.edit'),
      href: '/account/edit',
      icon: CalendarIcon,
      current: router.pathname.endsWith('/account/edit'),
    },
    {
      name: t('app.ui.menu.edit_address'),
      href: AccountRoutes.addressList,
      icon: HomeIcon,
      current: router.pathname.endsWith(AccountRoutes.addressList),
    },
    {
      name: t('app.ui.menu.sign_out'),
      href: '#',
      icon: LogoutIcon,
      current: false,
      onClick: () => handleSignOut(),
    },
  ];

  return (
    <div className="rounded-md shadow-default p-4 md:p-6 bg-white">
      <div className="pb-4 font-bold">{t('app.ui.menu.your_account')}</div>

      <nav className="space-y-1" aria-label="Sidebar">
        {navigation.map((item) => (
          <Link
            href={item.href}
            key={item.name}
            onClick={() => (item.onClick ? item.onClick() : null)}
            className={classNames(
              item.current ? 'text-purple' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
              'flex items-center py-2 text-sm font-medium rounded-md'
            )}
            aria-current={item.current ? 'page' : undefined}
          >
            <item.icon
              className={classNames(
                item.current ? 'text-purple' : 'text-gray-400',
                'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
              )}
              aria-hidden="true"
            />
            <span className="truncate">{item.name}</span>
          </Link>
        ))}
      </nav>
    </div>
  );
}

export default AccountMenu;
