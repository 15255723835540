import { Order } from 'types/order';
import { useTranslation } from 'react-i18next';
import useResponsive from 'hooks/useResponsive';
import { prettyDate } from 'lib/date';

export function BoxMostRecentOrderHeader({ order }: { order: Order }) {
  const { t } = useTranslation();
  const { isLg } = useResponsive();
  return (
    <div className="lg:flex lg:justify-between mb-4">
      <h2 className="font-bold">{t('app.ui.my_most_recent_order')}</h2>
      <div className="text-sm font-medium">
        {t('app.ui.order_number')}: #{order.id}
      </div>
      {!isLg && <div className="text-sm font-medium">{prettyDate(order.checkoutCompletedAt)}</div>}
    </div>
  );
}
