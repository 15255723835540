import { doFetch, getPublicRuntimeConfig, resolveApiUrl } from 'lib/api';
import API_ENDPOINTS from 'constants/routes/api';
import { DEFAULT_ADYEN_API_VERSION } from 'constants/common';

const { adyenApiVersion } = getPublicRuntimeConfig();
const version = adyenApiVersion || DEFAULT_ADYEN_API_VERSION;

export default async function fetchCompleteCheckout(
  orderTokenValue: string,
  authToken?: string
): Promise<Response> {
  const headers = {
    accept: 'application/json',
    'Content-Type': 'application/merge-patch+json',
  };

  if (authToken) {
    Object.assign(headers, { 'X-AUTH-TOKEN': authToken });
  }

  const endpoint = resolveApiUrl(API_ENDPOINTS.GetCompleteCheckout(orderTokenValue));
  return await doFetch(endpoint, {
    body: JSON.stringify({ notes: '', version }),
    headers: headers,
    method: 'PATCH',
    redirect: 'follow',
    mode: 'cors',
  });
}
