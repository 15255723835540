import { FC } from 'react';

interface Props {
  name: string;
  href: string;
}

export const NavigationItem: FC<Props> = (item) => (
  <li>
    <a href={item.href}>{item.name}</a>
  </li>
);
