import classNames from 'classnames';
import Link from 'next/link';
import { AVDD_SIZES, AvddIconName, SupportedSizes } from 'types/icon';
import { AvddIcon } from 'components/taxons/AvddIcon';

interface Props {
  title: string;
  link: string;
  iconName: AvddIconName | null;
  size: typeof AVDD_SIZES.md | typeof AVDD_SIZES.lg;
  isActive: boolean;
  onClick?: Function;
}

export const MenuHorizontalItem = ({ title, link, iconName, size, isActive, onClick }: Props) => {
  const wrapperClassNames = classNames(
    'min-w-max flex-1 md:content-center md:text-center text-sm font-semibold h-full',
    {
      'text-t-main-menu-active hover:text-primary': isActive,
      'text-gray-800 hover:text-gray-500': !isActive,
    }
  );

  const itemClassNames = classNames('flex items-center text-center h-full', {
    'flex-col': size === AVDD_SIZES.lg,
  });

  const titleClassName = classNames('', {
    'pr-0.5 pl-1.5': size === AVDD_SIZES.md,
    'w-[85px]': size === AVDD_SIZES.lg,
  });

  return (
    <Link href={link} prefetch={false} className={wrapperClassNames} onClick={() => onClick?.()}>
      <span className={itemClassNames}>
        <AvddIcon name={iconName} size={size} />
        <span className={titleClassName}>{title}</span>
      </span>
    </Link>
  );
};
