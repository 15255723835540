import {
  AdyenSessionCheckout,
  OrderPaymentState,
  Payment,
  PaymentResponse,
  PaymentSession,
} from 'types/types';
import config from 'next/config';
import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin/Dropin';
import { DropinComponentState } from '@adyen/adyen-web/dist/types/components/Dropin/types';
import { NextRouter } from 'next/router';
import AdyenCheckout from '@adyen/adyen-web';
import { TFunction } from 'i18next';
import { captureError } from './error';
import CheckoutRoutes from 'constants/routes/checkout';
import paymentApi from 'api/payment';

const { publicRuntimeConfig } = config();
const adyenSessionCheckoutLocalStorageKey = 'adyen-checkout__session';

export function presentPspDetails(payment: Payment, t: TFunction): string {
  if (!payment.pspMethod) {
    return '';
  }

  if (payment.pspMethod === 'ideal') {
    return 'Ideal' + ' - ' + mapIssuerToBank(payment.pspBankId);
  }

  const schemeMap: { [key: string]: string } = {
    mc: 'Mastercard',
    visa: 'Visa',
    bcmc: 'Bancontact card',
    bcmc_mobile: 'Payconiq',
  };

  return schemeMap.hasOwnProperty(payment.pspBankId)
    ? schemeMap[payment.pspBankId]
    : t('app.ui.payment_method.scheme');
}

type bankMap = {
  [key: string]: string;
};

const authorisableResultCodes = ['authorised', 'received'];

const bankIdToBankName: bankMap = {
  '0031': 'ABN AMRO',
  '0761': 'ASN Bank',
  '0802': 'bunq',
  '0804': 'Handelsbanken',
  '0721': 'ING Bank',
  '0801': 'Knab',
  '0021': 'Rabobank',
  '0771': 'Regiobank',
  '0805': 'Revolut',
  '0751': 'SNS Bank',
  '0511': 'Triodos Bank',
  '0161': 'Van Lanschot Bankiers',

  // test banks
  '1121': 'Test Issuer',
  '1151': 'Test Issuer 2',
  '1152': 'Test Issuer 3',
  '1153': 'Test Issuer 4',
  '1154': 'Test Issuer 5',
  '1155': 'Test Issuer 6',
  '1156': 'Test Issuer 7',
  '1157': 'Test Issuer 8',
  '1158': 'Test Issuer 9',
  '1159': 'Test Issuer 10',
  '1160': 'Test Issuer Refused',
  '1161': 'Test Issuer Pending',
  '1162': 'Test Issuer Cancelled',
};
export function isResultCodeAuthorisable(resultCode: string): boolean {
  return authorisableResultCodes.includes(resultCode.toLowerCase());
}

export function mapIssuerToBank(bankId: string): string | null {
  return bankIdToBankName.hasOwnProperty(bankId) ? bankIdToBankName[bankId] : null;
}

export function getInvoiceUrl(id: string, token: string): string {
  return `${publicRuntimeConfig.apiUrl}/api/v2/shop/invoices/${id}/download?x-auth-token=${token}`;
}

export function isOrderPayed(orderPaymentState: OrderPaymentState): boolean {
  return [
    OrderPaymentState.paid,
    OrderPaymentState.refunded,
    OrderPaymentState.partiallyRefunded,
  ].includes(orderPaymentState);
}

export async function startSession({ orderTokenValue }: { orderTokenValue: string }) {
  return await paymentApi.fetchStartSession(orderTokenValue);
}

export async function handleNotFound({
  clearCart,
  router,
  orderTokenValue,
}: {
  clearCart: () => void;
  router: NextRouter;
  orderTokenValue: string;
}) {
  clearCart();
  await router.push(`/account/orders/${orderTokenValue}`);
}

export async function handleUnrecoverableError({
  dropin,
  router,
  orderTokenValue,
}: {
  dropin: DropinElement;
  router: NextRouter;
  orderTokenValue: string;
}) {
  dropin.setStatus('ready');

  await router.push(`/account/orders/${orderTokenValue}`);
}

export const buildAuthorizeCheckout = ({
  orderTokenValue,
  orderId,
  paymentSessionId,
  afterPaymentCompleted,
}: {
  orderTokenValue: string;
  orderId?: string | null;
  paymentSessionId: string;
  afterPaymentCompleted: () => void;
}): Promise<typeof AdyenCheckout> => {
  // @ts-ignore
  return new window.AdyenCheckout({
    session: {
      id: paymentSessionId,
    },
    environment: publicRuntimeConfig.paymentEnvironment,
    clientKey: publicRuntimeConfig.paymentClientKey,
    locale: 'nl-NL',
    onError: (error: Error) => {
      captureError(error, {
        orderTokenValue,
        paymentSessionId: paymentSessionId,
      });
    },
    onPaymentCompleted: async (result: { resultCode: string }) => {
      await paymentApi.fetchCompleteSession(paymentSessionId, orderTokenValue, result.resultCode);

      if (isResultCodeAuthorisable(result.resultCode)) {
        window.location.replace(CheckoutRoutes.thankYouWithOrderDetails(orderTokenValue, orderId));
        afterPaymentCompleted();
        return;
      }

      window.location.replace(`/account/orders/${orderTokenValue}`);
      afterPaymentCompleted();
    },
  });
};

export const buildCheckout = ({
  onSubmit,
  paymentSession,
  t,
  orderTokenValue,
  orderId,
  afterPaymentCompleted,
  onAdyenError,
}: {
  onSubmit?: (
    state: DropinComponentState,
    dropin: DropinElement,
    actions: {
      resolve: (state: DropinComponentState) => Promise<void>;
      reject: () => Promise<void>;
    },
    paymentSession: PaymentSession
  ) => Promise<PaymentResponse | void>;
  paymentSession: PaymentSession;
  orderTokenValue?: string | null;
  orderId?: string | null;
  t: TFunction;
  afterPaymentCompleted: Function;
  onAdyenError?: Function | null;
}): Promise<typeof AdyenCheckout | null> => {
  if (typeof window !== 'undefined') {
    // @ts-ignore
    const config = {
      translations: {
        'nl-NL': {
          payButton: t('app.ui.pay'),
          continue: t('app.ui.pay'),
        },
      },
      paymentMethodsConfiguration: {
        ideal: {
          showImage: true,
        },
        card: {
          hasHolderName: true,
          holderNameRequired: true,
        },
        bcmc_mobile: {
          name: t('app.ui.payconiq'),
        },
        paywithgoogle: {
          buttonLocale: 'nl',
          buttonSizeMode: 'fill',
          merchantName: publicRuntimeConfig.googlepay.merchantName,
          gatewayMerchantId: publicRuntimeConfig.googlepay.gatewayMerchantId,
          merchantId: publicRuntimeConfig.googlepay.merchantId,
        },
        applepay: {
          buttonLocale: 'nl',
          buttonSizeMode: 'fill',
          merchantName: publicRuntimeConfig.googlepay.merchantName,
          merchantId: publicRuntimeConfig.googlepay.merchantId,
        },
      },
      session: {
        id: paymentSession.id,
        sessionData: paymentSession.sessionData,
      },
      onError: (error: Error) => {
        const translatableAdyenMessage = transformAdyenErrorMessageInTranslatable(error);
        const translatedMessage = t(translatableAdyenMessage);

        if (translatableAdyenMessage === translatedMessage) {
          captureError(error);
          return;
        }

        if (onAdyenError) {
          onAdyenError(translatedMessage);
        }
      },
      environment: publicRuntimeConfig.paymentEnvironment,
      clientKey: publicRuntimeConfig.paymentClientKey,
      locale: 'nl-NL',
      showPayButton: true,
      onPaymentCompleted: (result: { resultCode: string }) => {
        if (isResultCodeAuthorisable(result.resultCode)) {
          window.location.replace(
            CheckoutRoutes.thankYouWithOrderDetails(orderTokenValue, orderId)
          );
          afterPaymentCompleted();

          return;
        }

        window.location.replace(`/account/orders/${orderTokenValue}`);
        afterPaymentCompleted();
      },
    };

    if (onSubmit) {
      // @ts-ignore
      config.beforeSubmit = async (data, component, actions) => {
        return onSubmit(data, component, actions, paymentSession);
      };
    }

    // @ts-ignore
    return new window.AdyenCheckout(config);
  }
  return Promise.resolve(null);
};

function transformAdyenErrorMessageInTranslatable(error: Error): string {
  return (
    'app.ui.payment.error.' +
    error.message.replaceAll(' ', '_').replaceAll('.', '').replaceAll(':', '').toLowerCase()
  );
}

export function isAdyenCheckoutSessionStored(sessionId: string): boolean {
  const adyenCheckoutSession = getAdyenCheckoutSession();

  return adyenCheckoutSession?.id === sessionId;
}

export function getAdyenCheckoutSession(): AdyenSessionCheckout | null {
  const rawAdyenCheckoutSession = window.localStorage.getItem(adyenSessionCheckoutLocalStorageKey);

  return rawAdyenCheckoutSession ? JSON.parse(rawAdyenCheckoutSession) : null;
}

export function setAdyenCheckoutSession(adyenSessionCheckout: AdyenSessionCheckout): void {
  window.localStorage.setItem(
    adyenSessionCheckoutLocalStorageKey,
    JSON.stringify(adyenSessionCheckout)
  );
}
