import { BoxHeader } from 'components/order/box/BoxHeader';
import { BoxMostRecentOrderHeader } from 'components/order/box/BoxMostRecentOrderHeader';
import { BoxFooter } from 'components/order/box/BoxFooter';
import { BoxItems } from 'components/order/box/BoxItems';
import { BoxItem } from 'components/order/box/BoxItem';
import { BoxViewOrderDetailsLink } from 'components/order/box/BoxViewOrderDetailsLink';
import { BoxViewInvoiceLink } from 'components/order/box/BoxViewInvoiceLink';
import { BoxViewOrderDetailsButton } from 'components/order/box/BoxViewOrderDetailsButton';
import { ReactNode } from 'react';
import { BoxViewAllOrdersLink } from 'components/order/box/BoxViewAllOrdersLink';

interface OrderBoxProps {
  children: ReactNode;
}

export const Box = ({ children, ...rest }: OrderBoxProps) => (
  <div className="p-4 rounded-md shadow-default bg-white mb-3" {...rest}>
    {children}
  </div>
);

Box.Header = BoxHeader;
Box.MostRecentOrderHeader = BoxMostRecentOrderHeader;
Box.Footer = BoxFooter;
Box.Items = BoxItems;
Box.Item = BoxItem;

Box.ViewInvoiceLink = BoxViewInvoiceLink;
Box.ViewAllOrdersLink = BoxViewAllOrdersLink;
Box.ViewOrderDetailsLink = BoxViewOrderDetailsLink;
Box.ViewOrderDetailsButton = BoxViewOrderDetailsButton;

export default Box;
