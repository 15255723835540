import Link from 'next/link';
import React from 'react';

interface Props {
  text: string;
  href: string;
  rightArrowIcon?: boolean;
  onClick?: () => void;
  color?: 'orange' | 'purple';
}

export default function BigLink({
  text,
  href,
  rightArrowIcon,
  onClick = () => {},
  color = 'orange',
}: Props): JSX.Element {
  return (
    <Link
      href={href}
      onClick={onClick}
      className={`inline-flex justify-center w-full px-4 py-2 font-bold text-${color}`}
    >
      {text}
      {rightArrowIcon && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
      )}
    </Link>
  );
}
