import { Taxon } from 'types/types';
import { taxonClick } from 'modules/tracking/events/events';
import { MenuHorizontalItem } from 'components/navbar/MenuHorizontalItem';
import useLocationNavigationLink from 'hooks/location/useLocationNavigationLink';
import { useTranslation } from 'react-i18next';
import { getActiveTaxon } from 'redux/common/common.slice';
import { useAppSelector } from 'redux/appStore';
import { AVDD_SIZES, ICON_HOME_PAGE } from 'types/icon';

interface Props {
  taxons?: Taxon[];
  renderAsHomePage?: boolean;
}

export function MenuHorizontal({ taxons, renderAsHomePage = false }: Props) {
  const { getHref, getHomePageHref } = useLocationNavigationLink();
  const { t } = useTranslation();
  const activeTaxon = useAppSelector(getActiveTaxon);

  const trackTaxonClick = async (taxon: Taxon) => {
    await taxonClick({ taxon });
  };

  return (
    <div
      className="container overflow-auto justify-items-center scrollbar-hide"
      data-testid="mainTaxonMenu"
    >
      <div className="flex pt-1 pb-3 md:pb-4 gap-2">
        {!renderAsHomePage && (
          <>
            <MenuHorizontalItem
              link={getHomePageHref}
              isActive={false}
              title={t('app.ui.menu.home')}
              iconName={ICON_HOME_PAGE}
              size={AVDD_SIZES.md}
            />
            <div className="h-5 w-px bg-border-color mt-1 xl:-ml-8 xl:mr-3" />
          </>
        )}
        {taxons?.map((taxon: Taxon) => (
          <MenuHorizontalItem
            key={taxon.id}
            link={getHref(taxon)}
            isActive={taxon.id === activeTaxon?.id}
            onClick={() => trackTaxonClick(taxon)}
            title={taxon.name}
            iconName={taxon.icon}
            size={renderAsHomePage ? AVDD_SIZES.lg : AVDD_SIZES.md}
          />
        ))}
      </div>
    </div>
  );
}
