import { useMemo } from 'react';
import { AppPromoStoreLink } from 'components/appPromoBanner/AppPromoStoreLink';
import useResponsive from 'hooks/useResponsive';
import { AppStoreCampaigns } from 'constants/appStores';
import { getAppStoreByUserDevice } from 'lib/appPromo/appPromo';

export default function AppDownloadButton({ campaign }: { campaign: AppStoreCampaigns }) {
  const { isMobile } = useResponsive();

  const appStore = useMemo(() => {
    return getAppStoreByUserDevice({ campaign });
  }, [campaign]);

  if (!isMobile) {
    return null;
  }

  return (
    <AppPromoStoreLink
      appStore={appStore}
      trackingPosition={3}
      width={150}
      height={75}
      shouldOpenInNewTab={true}
    />
  );
}
