import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import useStore from 'store';
import PaymentMethods from '../common/PaymentMethods';
import { getSocialUrls } from 'components/footer/getSocialUrls';
import AppDownloadButton from 'components/footer/AppDownloadButton';
import { APP_STORE_CAMPAIGNS, DESKTOP_APP_URL } from 'constants/appStores';
import { TALPA_COOKIES_LINK } from 'constants/business';
import { NavigationItem } from 'components/common/NavigationItem';

const navigation = {
  customerService: [
    { name: t('app.ui.footer.feedback'), href: '/contact' },
    { name: t('app.ui.footer.send_mail'), href: '/contact' },
    { name: t('app.ui.footer.faq'), href: '/faq' },
    { name: t('app.ui.footer.app'), href: DESKTOP_APP_URL },
  ],
  conditions: [
    {
      name: t('app.ui.footer.general_conditions'),
      href: '/blog/voorwaarden',
      target: '_blank',
      rel: undefined,
    },
    {
      name: t('app.ui.footer.privacy'),
      href: '/blog/privacy',
      target: '_blank',
      rel: 'nofollow',
    },
  ],
  about: [
    {
      name: t('app.ui.footer.partners'),
      href: '/blog/partner-worden-van-actievandedag',
    },
    { name: t('app.ui.footer.affiliates'), href: '/blog/affiliates' },
    { name: t('app.ui.footer.about_avdd'), href: '/blog/about' },
    { name: t('app.ui.footer.blog'), href: '/blog' },
  ],
  social: getSocialUrls(),
};

export function Footer() {
  const { t } = useTranslation();
  const [_, setCookiePreferencesModalOpen] = useStore('cookiePreferencesModalOpen');

  return (
    <footer className="text-white bg-footer-bg" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="container py-4">
        <div className="xl:grid xl:grid-cols-2 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 px-4 mt-4 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="font-semibold tracking-wider">
                  {t('app.ui.footer.customer_service')}
                </h3>
                <ul role="list" className="mt-4 space-y-2">
                  {navigation.customerService.map((item) => (
                    <NavigationItem key={item.name} {...item} />
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="font-semibold tracking-wider">{t('app.ui.footer.conditions')}</h3>
                <ul role="list" className="mt-4 space-y-2">
                  <li key={'cookie'}>
                    <a href={TALPA_COOKIES_LINK} target={'_blank'} rel="noreferrer nofollow">
                      {t('app.ui.footer.cookies')}
                    </a>
                  </li>
                  <li key={'cookie-settings'}>
                    <a
                      href={'#'}
                      onClick={(e) => {
                        e.preventDefault();
                        void setCookiePreferencesModalOpen(true);
                      }}
                    >
                      {t('app.ui.footer.cookie_settings')}
                    </a>
                  </li>
                  {navigation.conditions.map((item) => (
                    <NavigationItem key={item.name} {...item} />
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div className="">
                <h3 className="font-semibold tracking-wider">{t('app.ui.footer.about_us')}</h3>
                <ul role="list" className="mt-4 space-y-2">
                  {navigation.about.map((item) => (
                    <NavigationItem key={item.name} {...item} />
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="mb-4 font-semibold tracking-wider">{t('app.ui.footer.updates')}</h3>
                <div className="flex space-x-6">
                  {navigation.social.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      target={item.target}
                      className="text-gray-400 hover:text-gray-500"
                      rel={item.rel}
                    >
                      <span className="sr-only">{item.name}</span>
                      <item.icon aria-hidden="true" />
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container px-6 mx-auto">
          <div className="flex flex-col items-center mt-16">
            <p>{t('app.ui.footerPriceNote')}</p>
            <div className="mt-12">
              <PaymentMethods />
            </div>
            <div className="py-10 text-center sm:w-2/3">
              <p className="text-sm font-bold text-gray-500">
                &copy; {t('app.ui.footer.copyright', { currentYear: new Date().getUTCFullYear() })}
              </p>
            </div>
            <div className="pb-14 text-center sm:w-2/3">
              <AppDownloadButton campaign={APP_STORE_CAMPAIGNS.footer} />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
