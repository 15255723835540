import { OrderItem } from 'types/order';
import Image from 'next/image';
import { BookingCardDescription } from 'components/booking/BookingCardDescription';
import Price from 'components/cart/Price';

export function BoxItem({ item, isLg }: { item: OrderItem; isLg: boolean }) {
  const title = item.booking ? item.booking.title : `${item.quantity} x ${item.variant.name}`;
  const showOriginalUnitPrice = item.originalUnitPrice > item.unitPrice;

  return (
    <div key={item.id} className="mb-4">
      <div className="flex items-center sm:items-start">
        <div className="h-20 w-24 flex-shrink-0 overflow-hidden rounded-lg bg-gray-200 sm:h-32 sm:w-40">
          {item.variant.src && (
            <Image
              width={170}
              height={140}
              src={item.variant.src}
              alt={item.variant.name}
              className="h-full w-full object-cover object-center"
              quality={isLg ? 100 : 75}
            />
          )}
        </div>
        <div className="ml-6 mt-3 flex-1 text-sm">
          <div className="text-base font-semibold text-gray-900">
            <h5 data-aq={'orderProductTitle'}>{title}</h5>
            {item.booking && <BookingCardDescription booking={item.booking} />}
            <div className="mt-2 sm:mt-0">
              <div className="flex justify-between mt-1 mr-2 font-medium">
                {showOriginalUnitPrice && (
                  <span className="mr-4 text-gray-500">
                    <Price
                      prefix="€"
                      data-aq={'originalUnitPrice'}
                      striked
                      price={item.originalUnitPrice}
                    />
                  </span>
                )}
                <span>
                  <Price prefix="€" data-aq={'currentUnitPrice'} bold price={item.unitPrice} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
