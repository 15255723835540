import { doFetch, resolveApiUrl } from 'lib/api';
import API_ENDPOINTS from 'constants/routes/api';

type FetchPaymentValidationProps = {
  orderTokenValue: string;
  paymentSessionId: string;
};

export default async function fetchPaymentValidation({
  orderTokenValue,
  paymentSessionId,
}: FetchPaymentValidationProps): Promise<any> {
  const endpoint = resolveApiUrl(API_ENDPOINTS.GetValidatePayment());

  return await doFetch(endpoint, {
    body: JSON.stringify({ orderTokenValue, paymentSessionId }),
    headers: { accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'POST',
    redirect: 'follow',
    mode: 'cors',
  });
}
