import { CustomerService } from './CustomerService';
import { UniqueSellingPoints } from './UniqueSellingPoints';
import { classNames } from 'lib/classes';
import { TrustpilotWidget } from 'components/trustpilot/trustpilot.widget';

interface Props {
  singleColumn?: boolean;
}

export const PreFooter = ({ singleColumn }: Props) => (
  <div className="container mt-4 mb-4 space-y-4">
    <div
      className={classNames(
        'flex flex-col gap-4 mx-auto mt-6 md:grid',
        singleColumn ? 'md:grid-cols-1' : 'md:grid-cols-2'
      )}
    >
      {singleColumn ? (
        <div className="space-y-4">
          <UniqueSellingPoints />
          <CustomerService />
        </div>
      ) : (
        <>
          <UniqueSellingPoints />
          <CustomerService />
        </>
      )}
    </div>
    <div className="p-4 rounded-md shadow-default bg-white">
      <div className="h-60 py-12">
        <TrustpilotWidget />
      </div>
    </div>
  </div>
);
