import Image from 'next/image';

import { paymentMethods } from 'constants/payments/methods';

export const PaymentMethods = () => (
  <div className="flex gap-5 justify-center h-20 mx-4">
    {paymentMethods.icons.map((item) => (
      <span key={item.name} className="my-auto">
        <Image
          key={item.path}
          className="object-contain"
          src={item.path}
          width={80}
          height={80}
          alt={item.name}
        />
      </span>
    ))}
  </div>
);

export default PaymentMethods;
