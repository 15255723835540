import CheckIcon from '@mui/icons-material/Check';

export default function Usp({ text }: { text: string }) {
  return (
    <div className="pb-2">
      <span className="mr-2 align-top text-t-green">
        <CheckIcon />
      </span>
      <span>{text}</span>
    </div>
  );
}
