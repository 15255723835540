import { ChangeEvent, FormEvent, KeyboardEvent, ReactNode, useState } from 'react';
import useSession from 'hooks/useSession';
import useStore from 'store';
import handleSubscribeToNewsletter from 'lib/handleSubscribeToNewsletter';
import userApi from 'api/user';
import { NewsletterSubscriptionLocation } from 'constants/newsletterSubscriptionLocation';
import validation from 'lib/validation';
import { useAppSelector } from 'redux/appStore';
import { getNewsletterSubscriptionLocation } from 'redux/common/common.slice';

export type NewsLetterSignupPresentationProps = {
  isInvalid: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  email: string;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onEmailChange: (e: ChangeEvent<HTMLInputElement>) => void;
  preventSpace: (e: KeyboardEvent<HTMLInputElement>) => void;
};

type NewsLetterSignup = {
  websiteLocation: NewsletterSubscriptionLocation;
  newsLetterPresentation: (props: NewsLetterSignupPresentationProps) => ReactNode;
};

export const NewsletterSignup = ({ websiteLocation, newsLetterPresentation }: NewsLetterSignup) => {
  const [email, setEmail] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const sessionObject = useSession();
  const { session } = sessionObject;
  const [, setCustomer] = useStore('customer');
  const selectedLocation = useAppSelector(getNewsletterSubscriptionLocation);

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.trim();
    if (isInvalid) setIsInvalid(!validation.isEmail(e.target.value));
    setEmail(e.target.value);
  };

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    e?.stopPropagation();

    if (isSuccess) return;

    const isValidEmail = validation.isEmail(email);
    if (!isValidEmail) return setIsInvalid(true);

    setIsLoading(true);
    const coordinates = selectedLocation
      ? { latitude: selectedLocation.latitude, longitude: selectedLocation.longitude }
      : undefined;

    handleSubscribeToNewsletter(email, websiteLocation, websiteLocation, coordinates).then(
      (response) => {
        setIsLoading(false);
        setIsSuccess(response);
        if (session?.token) {
          userApi
            .fetchAccount(session?.token)
            .then(async (response) => {
              await setCustomer(response);
            })
            .catch((e) => console.log(`token ${session?.token} not working`, e));
        }
      }
    );
  };

  const preventSpace = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Space') event.preventDefault();
  };

  return (
    <>
      {newsLetterPresentation({
        isInvalid,
        isSuccess,
        isLoading,
        email,
        onSubmit: submit,
        onEmailChange,
        preventSpace,
      })}
    </>
  );
};

export default NewsletterSignup;
