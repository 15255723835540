import { doFetch, getPublicRuntimeConfig, resolveApiUrl } from 'lib/api';
import { PaymentGatewayUnprocessableError, UnsellableItemsInCart } from 'types/types';
import API_ENDPOINTS from 'constants/routes/api';
import { DEFAULT_ADYEN_API_VERSION } from 'constants/common';

const { adyenApiVersion } = getPublicRuntimeConfig();
const version = adyenApiVersion || DEFAULT_ADYEN_API_VERSION;

export default async function fetchStartSession(orderTokenValue: string): Promise<any> {
  const endpoint = resolveApiUrl(API_ENDPOINTS.GetStartSession);
  const res = await doFetch(endpoint, {
    body: JSON.stringify({ orderTokenValue, version }),
    headers: { accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'POST',
    redirect: 'follow',
    mode: 'cors',
  });

  if (res.status === 422) {
    const response = await res.json();

    if (response.error && response.error === 'app.checkout.unsellable_items_in_cart') {
      throw new UnsellableItemsInCart();
    }

    throw new PaymentGatewayUnprocessableError();
  }

  if (!res.ok) {
    throw new Error('Start payment failed');
  }

  const json = await res.json();
  return json.pgwResponse;
}
