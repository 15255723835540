import { Order } from 'types/order';
import { SessionValue } from 'types/types';
import { useTranslation } from 'react-i18next';
import { getInvoiceUrl, isOrderPayed } from 'lib/payment';

export function BoxViewInvoiceLink({ order, session }: { order: Order; session: SessionValue }) {
  const { t } = useTranslation();
  return (
    order.invoice &&
    isOrderPayed(order.paymentState) && (
      <a
        data-testid="viewInvoiceLink"
        href={getInvoiceUrl(order.invoice.id, session?.token || '')}
        className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 md:w-auto"
      >
        {t('app.ui.view_invoice')}
        <span className="sr-only">for order {order.id}</span>
      </a>
    )
  );
}
