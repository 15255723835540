import { doFetch, getPublicRuntimeConfig, resolveApiUrl } from 'lib/api';
import API_ENDPOINTS from 'constants/routes/api';
import { DEFAULT_ADYEN_API_VERSION } from 'constants/common';

const { adyenApiVersion } = getPublicRuntimeConfig();
const version = adyenApiVersion || DEFAULT_ADYEN_API_VERSION;

export default async function fetchCompleteSession(
  paymentSessionId: string,
  orderTokenValue: string,
  resultCode: string
): Promise<void> {
  const endpoint = resolveApiUrl(API_ENDPOINTS.GetCompleteSession);
  const res = await doFetch(endpoint, {
    body: JSON.stringify({ paymentSessionId, orderTokenValue, resultCode, version }),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    redirect: 'follow',
    mode: 'cors',
  });

  if (!res.ok) {
    throw new Error('Complete payment failed');
  }

  return;
}
