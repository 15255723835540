import ActivityIndicator from '../../ActivityIndicator';
import NewsletterLocationSelector from 'components/newsletter/NewsletterLocationSelector';
import { Trans, useTranslation } from 'react-i18next';
import { NewsLetterSignupPresentationProps } from 'components/newsletter/functional/NewsletterSignup';
import Link from 'next/link';
import { TALPA_PRIVACY_LINK } from 'constants/business';

const NewsletterSignupContent = ({
  isInvalid,
  isSuccess,
  isLoading,
  email,
  onSubmit,
  onEmailChange,
  preventSpace,
}: NewsLetterSignupPresentationProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center w-full py-6 px-4 rounded-md lg:rounded-md bg-primary">
      <h2 className="text-2xl text-white lg:w-[400px] text-center">
        {t('app.ui.newsletterbanner.title')}
      </h2>
      <p className="inline text-center text-white sm:block md:w-1/2 my-2">
        <Trans i18nKey="app.ui.newsletterbanner.subtitle">
          placeholdertext
          <Link href={TALPA_PRIVACY_LINK} className="underline" />
        </Trans>
      </p>

      {isInvalid && <p className="text-red-800">{t('app.ui.newsletter.invalid_email')}</p>}
      {isSuccess && <p className="text-green-800">{t('app.ui.newsletter.success')}</p>}
      <form className="flex flex-wrap mx-4 my-6 lg:w-8/12 md:flex-nowrap" onSubmit={onSubmit}>
        <NewsletterLocationSelector />

        <div className="w-full flex mt-2 md:mt-0 bg-white rounded-lg">
          <input
            type="email"
            className="w-full py-2 pl-4 bg-transparent"
            placeholder={t('app.ui.newsletterbanner.emailinput')}
            value={email}
            onChange={onEmailChange}
            onKeyDown={preventSpace}
          />
          <button
            type="submit"
            className="flex items-center px-3 md:px-8 text-white rounded-r-lg bg-purple hover:bg-purple"
            disabled={isSuccess}
          >
            {isLoading && <ActivityIndicator className="ml-4" color="white" />}
            {t('app.ui.newsletter.subscribe')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewsletterSignupContent;
