import { Call } from '@mui/icons-material';
import Link from 'next/link';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FreeBreakfastOutlinedIcon from '@mui/icons-material/FreeBreakfastOutlined';
import { useTranslation } from 'react-i18next';
import config from 'next/config';
import Box from './order/box/Box';

const { publicRuntimeConfig } = config();

export function CustomerService() {
  const { t } = useTranslation();

  return (
    <Box>
      <div className="filter md:text-center sm:text-left">
        <div className="mb-4 text-2xl font-extrabold">{t('app.ui.customer_service')}</div>
        <div className="flex flex-col md:grid md:grid-cols-2 md:mb-6">
          <div className="mr-6 md:text-right sm:text-left">
            <Call className="mr-4 text-purple" />
            <Link href={'tel:' + publicRuntimeConfig.customerCarePhoneNumber}>
              {publicRuntimeConfig.customerCarePhoneNumberPretty}
            </Link>
          </div>
          <div className={'text-left mb-2 text-sm'}>{t('app.ui.phoneReachableTimes')}</div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:mx-12">
          <div>
            <EmailOutlinedIcon className="mr-4 text-purple" />
            <Link href={'/contact'}>{t('app.ui.footer.send_mail')}</Link>
          </div>
          <div>
            <FreeBreakfastOutlinedIcon className="mr-4 text-purple" />
            <Link href={'/faq'}>{t('app.ui.footer.faq')}</Link>
          </div>
        </div>
      </div>
    </Box>
  );
}
