import { useTranslation } from 'react-i18next';
import Link from 'next/link';

export function BoxViewOrderDetailsLink({ orderDetailsPath }: { orderDetailsPath: string }) {
  const { t } = useTranslation();
  return (
    <div>
      <Link href={orderDetailsPath} className="text-purple cursor-pointer underline">
        {t('app.ui.view_this_order')}
      </Link>
    </div>
  );
}
