import fetchCompleteCheckout from 'api/payment/fetchCompleteCheckout';
import fetchCompleteSession from 'api/payment/fetchCompleteSession';
import fetchPaymentSession from 'api/payment/fetchPaymentSession';
import fetchStartPayment from 'api/payment/fetchStartPayment';
import fetchStartSession from 'api/payment/fetchStartSession';
import fetchPaymentValidation from 'api/payment/fetchValidatePayment';

const paymentApi = {
  fetchStartPayment,
  fetchStartSession,
  fetchPaymentSession,
  fetchCompleteSession,
  fetchCompleteCheckout,
  fetchPaymentValidation,
};

export default paymentApi;
