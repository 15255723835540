import { Order } from 'types/order';
import { BoxItem } from 'components/order/box/BoxItem';
import { useCallback } from 'react';
import { useRouter } from 'next/router';

export function BoxItems({ order, isLg }: { order: Order; isLg: boolean }) {
  const router = useRouter();

  const goToOrderDetails = useCallback(
    () => router.push(`/account/orders/${order.tokenValue}`),
    [order.tokenValue, router]
  );

  return (
    <div className="flex-col cursor-pointer" onClick={goToOrderDetails}>
      {order.items.map((item) => (
        <BoxItem item={item} isLg={isLg} key={item.id} />
      ))}
    </div>
  );
}
