import { Order } from 'types/order';
import { useTranslation } from 'react-i18next';
import { prettyDate } from 'lib/date';

export function BoxHeader({ order }: { order: Order }) {
  const { t } = useTranslation();
  return (
    <div className="lg:flex lg:justify-between mb-4">
      <h2 className="font-bold">
        {t('app.ui.order_from', {
          orderDate: prettyDate(order.checkoutCompletedAt),
        })}
      </h2>
      <div className="text-sm text-right font-medium">
        {t('app.ui.order_number')}: #{order.id}
      </div>
    </div>
  );
}
